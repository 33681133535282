<template>
  <div
    id="business-address"
    class="row align-items-center justify-content-center"
  >
    <div class="col-12">
      <h1 class="font-32 text-center mb-3">
        Your Business Address
      </h1>
      <div class="font-17 text-center">
        Your Showdigs subscription is now live! <br>
        As a last step, we will need your business address.
      </div>
      <ElForm
        ref="form"
        class="mt-4"
        :model="address"
        :rules="rules"
        @submit.native.prevent="submit"
      >
        <ElFormItem prop="line1">
          <SdFloatLabel label="Address line 1">
            <ElInput
              v-model="address.line1"
              placeholder="Address line 1"
            />
          </SdFloatLabel>
        </ElFormItem>
        <ElFormItem
          prop="line2"
          class="mt-4"
        >
          <SdFloatLabel label="Address line 2">
            <ElInput
              v-model="address.line2"
              placeholder="Address line 2"
            />
          </SdFloatLabel>
        </ElFormItem>
        <ElFormItem
          prop="city"
          class="mt-4"
        >
          <SdFloatLabel label="City">
            <ElInput
              v-model="address.city"
              placeholder="City"
            />
          </SdFloatLabel>
        </ElFormItem>
        <ElFormItem
          prop="state"
          class="mt-4"
        >
          <SdFloatLabel label="State">
            <ElSelect
              v-model="address.state"
              filterable
              placeholder="State"
              :disabled="address.country !== 'US'"
            >
              <ElOption
                v-for="{label, value} in StateOptions"
                :key="value"
                :label="label"
                :value="value"
              />
            </ElSelect>
          </SdFloatLabel>
        </ElFormItem>
        <ElFormItem
          prop="country"
          class="mt-4"
        >
          <SdFloatLabel label="Country">
            <ElSelect
              v-model="address.country"
              filterable

              placeholder="Country"
              @input="countryChanged"
            >
              <ElOption
                v-for="{label, value} in CountryOptions"
                :key="value"
                :label="label"
                :value="value"
              />
            </ElSelect>
          </SdFloatLabel>
        </ElFormItem>
        <ElFormItem
          prop="postal_code"
          class="mt-4"
        >
          <SdFloatLabel label="Postal code">
            <ElInput
              v-model="address.postal_code"
              placeholder="Postal code"
            />
          </SdFloatLabel>
        </ElFormItem>
        <ElButton
          :loading="uiFlags.isLoading"
          type="primary w-100 mt-4"
          class="font-weight-normal font-17"
          @click="submit"
        >
          Submit
        </ElButton>
      </ElForm>
    </div>
    <ElButton
      id="logout-button"
      size="small"
      @click="logout"
    >
      Log out
    </ElButton>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import { showErrorNotify } from '@/utils/NotifyUtil';
import { redirectToSignIn } from '../../router';
import CountryOptions from '../../constants/CountryOptions';
import StateOptions from '../../constants/StateOptions';
import PostalCodePattern from '../../constants/PostalCodePattern';

export default {
  name: 'BusinessAddress',
  setup(props, context) {
    const store = context.root.$store;
    const route = context.root.$route;

    const uiFlags = reactive({
      isLoading: false,
    });

    const address = reactive({
      line1: '',
      line2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'US',
    });

    const rules = getRules();

    init();

    return {
      uiFlags,
      address,
      StateOptions,
      CountryOptions,
      PostalCodePattern,
      rules,
      submit,
      logout,
      countryChanged,
    };

    function init() {
      store.commit('Ui/setTestimonial', 3);

      const business = store.getters['Auth/business'];

      if (business && business.address && business.address.postal_code) {
        context.root.$router.push({
          name: 'app.properties',
        });
      }

      if (!route.params.stripeSourceId) {
        context.root.$router.push({
          name: 'app.subscribe',
        });
      }
    }

    async function submit() {
      try {
        uiFlags.isLoading = true;
        await context.refs.form.validate();
        await store.dispatch('Subscription/subscribe', {
          address,
          stripe_source_id: route.params.stripeSourceId,
        });
        await store.dispatch('Auth/me');
        context.root.$router.replace({
          name: 'app.properties',
          query: { welcome: true },
        });
      } catch (error) {
        if (!(error instanceof Error)) {
          return; // Validator failed
        }
        showErrorNotify(context, error.message);
      } finally {
        uiFlags.isLoading = false;
      }
    }

    async function logout() {
      await context.root.$store.dispatch('Auth/logout');
      redirectToSignIn(context);
    }

    function countryChanged() {
      if (address.country !== 'US') {
        address.state = '';
      }
    }

    function getRules() {
      return {
        line1: {
          required: true,
          type: 'string',
          message: 'Address line 1 is required',
        },
        line2: {
          type: 'string',
        },
        city: {
          required: true,
          type: 'string',
          message: 'City is required',
        },
        state: {
          validator: (rule, value, callback) => {
            if (address.country !== 'US') {
              return callback();
            }

            if (StateOptions.some((state) => state.value === value)) {
              return callback();
            }

            return callback('State is required');
          },
        },
        postal_code: {
          validator: (rule, value, callback) => {
            if (address.country === 'US') {
              if (PostalCodePattern.test(value)) {
                return callback();
              }
            } else if (value.length) {
              return callback();
            }

            return callback('Postal code is required');
          },
        },
        country: {
          required: true,
          type: 'string',
          enum: CountryOptions.map(({ value }) => value),
          message: 'Country is required',
        },
      };
    }
  },
};
</script>

<style lang="scss" scoped>
#business-address {
  #logout-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: $--font-size-small;

    @media (max-width: 768px) {
      top: -5rem;
      right: 0;
    }
  }
}
</style>
